.container {
  width: 100%;
  /* padding-top: 20px; */
  position: relative;
  overflow: hidden;
}

.section {
  width: 100%;
}

.topBackground {
  /* -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 50%, 0% 95%);
  clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 95%); */
  /* background: linear-gradient(to bottom left, #ededed, #ededed);
   */

  /* background-color: #052f2e;
  
  */

  background-image: url("../../assets/areas_de_practica_fondo.jpg");
  /* height: 710px; */
  background-size: cover;
  height: auto;
  width: 100%;
  padding-bottom: 100px;
  /* position: absolute; */
  top: 0px;
}

.first {
  display: flex;
  flex-direction: column;
}

.contentGrid {
  margin: auto;
}

.first .grid {
  display: grid;
  align-items: start;
  justify-content: start;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 60px;
}

.bottomBackground {
  /* -webkit-clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  background: linear-gradient(90deg, #002e2c, #002e2c); */
  background-image: url("../../assets/equipo_fondo.jpg");
  /* height: 710px; */
  background-size: cover;
  height: auto;
  padding-bottom: 150px;
  width: 100%;
  bottom: 0px;
}

.first,
.third,
.second {
  width: 100%;
  padding-top: 80px;
}
.second {
  padding-bottom: 80px;
  padding-left: 10px;
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .first .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 60px;
    max-width: 1300px;
  }

  .container {
    padding: 0px;
  }

  .topBackground {
    padding-bottom: 120px;
  }

  .first {
    gap: 12px;
  }
}

@media screen and (min-width: 1201px) {
  .container {
    padding: 0px;
  }

  .second,
  .topBackground {
    /* -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 90%);
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 90%); */
    /* height: 600px; */
    padding-bottom: 160px;
  }
  /* 
  .first .title {
    padding-top: 20px;
  } */

  .first {
    gap: 20px;
  }

  .first .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 200px;
  }
}

@media screen and (min-width: 1500px) {
  .topBackground,
  .bottomBackground {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .first,
  .second,
  .third {
    padding-top: 100px;
    width: 1200px;
    align-self: center;
  }
}
