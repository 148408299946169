.container {
  margin: 0px;
  padding: 0px;
  position: relative;
  height: 350px;
  width: 100%;
}

.image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  min-width: 120px;
  max-width: 350px;
  width: 20%;
}

@media screen and (min-width: 765px) {
  .container {
    margin: 0px;
    padding: 0px;
    position: relative;
    height: 550px;
    width: 100%;
  }

  .image {
    position: absolute;
    bottom: 0px;
    right: 0px;
    min-width: 120px;
    max-width: 350px;
    width: 20%;
  }
}
