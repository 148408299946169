.container,
.containerBlack {
  display: flex;
  padding: 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #001d1b;
  position: relative;

  height: 100%;
  width: 100%;
}

.container {
  background-color: rgb(238, 238, 238);
}

.containerBlack {
  background-color: #000306;
}

.logo {
  height: 80px;
  cursor: pointer;
}
