.container {
  /* height: 100%; */
  height: 80px;
  width: 100%;
}

.input {
  height: 35px;
  width: 100%;
  padding: 0px;
  font-size: 1rem;
  box-sizing: border-box;
  padding-left: 6px;
  outline: none;
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease-in-out;
}

.inputContainer {
  height: 35px;
  width: 100%;
  position: relative;
}

.input.error {
  border: 2px solid #ff452b;
  /* border-radius: 8px; */
  transition: 0.2s ease-in-out;
}

.input:focus {
  outline: none;
  border: 1px solid #a8a8a8;
  /* background-color: #f3f3f3; */
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}
.button {
  position: absolute;
  top: 10px;
  right: 5px;
  border: none;
  background: transparent;
}
.label {
  font-size: 0.8rem;
  letter-spacing: 1px;
  line-height: 1.2rem;
  font-weight: 600;
}

.input:disabled {
  background-color: #efefef;
}

.checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
}

.messageError {
  font-size: 0.9rem;
  color: #ff452b;
  padding: 5px 0px 5px 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Tablet */
@media screen and (min-width: 765px) {
  .input {
    height: 40px;
  }
}
