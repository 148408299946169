.containerBlack,
.containerGreen,
.containerGrey {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  align-items: center;
  max-height: 250px;
  gap: 30px;
  color: #ffffff;
  /* background: linear-gradient(90deg, #002e2c, #002e2c); */
}
.containerBlack {
  background-color: #0c0c0c;
  border-top: 0.5px solid #6484da35;
}

.containerGreen {
  background: #011f2a;
  border-top: 0.5px solid #d2ae6c35;
}

.containerGrey {
  background-color: #0c0c0c;
  border-top: 0.5px solid #6484da35;
}

.containerButton {
  display: flex;
  gap: 10px;
}

.logo {
  height: 90px;
  padding-right: 5px;
}

.links {
  display: none;
}

.icon img {
  width: 20px;
}

.icon svg path {
  fill: #fff;
}
.icon svg {
  width: 20px;
}

.firstColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  height: 100%;
}
.dataContainer {
  border-left: 1.5px solid rgba(255, 255, 255, 0.35);
  padding: 5px 16px;
  height: min-content;
}
.dataContact {
  height: min-content;
  line-height: 1.35rem;
  font-weight: 300;
  font-size: 0.9rem;
}
.prespacer {
  padding-right: 10px;
}
.spacer {
  border-left: 1.8px solid rgba(255, 255, 255, 0.35);
  padding: 0px 10px;
  height: min-content;
}

.rightsAndDevleoper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  font-size: 0.8rem;
  font-weight: 300;
}
.secondColumn .dgweb {
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  padding-left: 6px;
}

/* ------------ TABLET ------------ */
@media screen and (min-width: 765px) and (max-width: 1200px) {
  .containerBlack,
  .containerGreen,
  .containerGrey {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background: linear-gradient(90deg, #002e2c, #002e2c); */
  }

  .containerButton {
    display: flex;
    gap: 10px;
  }

  .links {
    display: contents;
  }
  .dataContact {
    height: min-content;
    line-height: 1.35rem;
    font-weight: 300;
    font-size: 0.8rem;
  }

  .rightsAndDevleoper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
    font-size: 0.8rem;
    font-weight: 300;
  }
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
  .containerBlack,
  .containerGreen,
  .containerGrey {
    padding: 30px 40px;
  }
}

@media screen and (min-width: 1201px) {
  .containerBlack,
  .containerGreen,
  .containerGrey {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-between;
    padding: 30px 40px;
    /* background: linear-gradient(90deg, #002e2c, #002e2c); */
  }

  .containerButton {
    display: flex;
    gap: 10px;
  }

  .containerLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
  }

  .logo {
    height: 110px;
    padding-right: 15px;
  }

  .links {
    display: contents;
  }

  .firstColumn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dataContainer {
    border-left: 2.5px solid rgba(255, 255, 255, 0.15);
    padding: 10px 25px;
    height: min-content;
  }
  .dataContact {
    height: min-content;
    line-height: 1.4rem;
    font-weight: 400;
    font-size: 1rem;
  }

  .secondColumn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
  }
  .rightsAndDevleoper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
    font-size: 0.85rem;
    font-weight: 400;
  }
  .secondColumn .dgweb {
    text-decoration: none;
    font-weight: 500;
    padding-left: 6px;
  }
}
