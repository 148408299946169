@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0px;
  /* font-family: "Roboto", sans-serif; */

  /**/
  font-family: "Inter", "Roboto", sans-serif;
}

.App,
.Layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
}
.Layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
  height: 100%;
}
