.containerHeader {
  z-index: 10;
  height: 100%;
}

.container {
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;

  /* border-bottom: 5px solid #002445; */

  /* background: linear-gradient(90deg, #0b0e1b, #002445); */
}

.logo {
  height: 100px;
}

.containerLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.header {
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #002445, #1d68ad);
  background-repeat: no-repeat;
  background-size: 100% 5px;
}

.button {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  background-color: #1d68ad;
  border: 2px solid #1d68ad;
  color: white;
  border-radius: 5px;
}

.button span {
  padding-left: 10px;
}

.button:hover {
  background-color: #cce5fd;
  color: rgb(0, 0, 0);
  border: 2px solid #1d68ad;
}
