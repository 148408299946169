.section {
  padding: 20px;
}

.title {
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: solid;
  padding: 80px 0px;
}

.slidesSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
