.container {
  width: 100%;
  /* height: 155px; */
  height: 100%;
}

.contentBlack {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  background-color: #000306;
}

.contentGreen {
  /* background-color: #042e2d; */

  background-color: rgb(238, 238, 238);

  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.contentGrey {
  background-color: #fafafa;
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.content {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  background-color: #ffffff;
}

.logo {
  height: 100%;
  max-width: 100px;
  cursor: pointer;
}

.containerButton {
  display: flex;
  gap: 10px;
}

.containerLinks {
  align-self: flex-end;
}

.header {
  width: 100%;
  height: 5px;
  background: linear-gradient(120deg, #000e0d, #001d1b);
  background-repeat: no-repeat;
  background-size: 100% 5px;
}

@media screen and (min-width: 850px) {
  .content,
  .contentBlack,
  .contentGreen {
    padding: 30px 40px;
  }
}
