.swiper.swiper-fade.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-watch-progress.swiper-backface-hidden.mySwiper {
  height: min-content;
}

.swiper-wrapper {
  min-height: 100%;
  height: fit-content;
}

.swiper-slide {
  height: auto;
}

.mySwiper.swiper {
  min-height: 100%;
  height: fit-content;
  background-color: rgb(0, 0, 0);
}

.mySwiper {
  width: 100%;
}

.mySwiper .swiper-slide {
  background-position: center;
  background-size: cover;
}

.mySwiper .swiper-slide img {
  width: 100%;
  height: 100%;
  align-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #002e2c;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px;
}
