.container ul,
.containerBlack ul,
.containerGreen ul,
.containerGrey ul {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 0px;
}

.containerBlack ul li a,
.container ul li a,
.containerGreen ul li a,
.containerGrey ul li a {
  text-decoration: none;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 600;
  transition: 0.25s ease-in-out;
  letter-spacing: 4px;

  padding-left: 4px;
  padding-bottom: 4px;
}

.containerBlack ul li a,
.containerGreen ul li a {
  color: #ffffff;
}

.containerBlack ul li a:hover,
.containerGreen ul li a:hover {
  color: rgb(200, 200, 200);
}

.containerGrey ul li a:hover {
  color: #0d5753;
}

.containerGrey ul li.active a {
  border-bottom: 2px solid #000000;
}

.containerBlack ul li.active a,
.containerGreen ul li.active a {
  border-bottom: 1.5px solid #ffffff;
  /* text-decoration: underline #ffffff; */
}

/* ------------ TABLET ------------ */
@media screen and (min-width: 765px) and (max-width: 1200px) {
  .container ul,
  .containerBlack ul,
  .containerGreen ul,
  .containerGrey ul {
    gap: 40px;
  }
}

/* ------------ DESKTOP ------------ */
@media screen and (min-width: 1201px) {
  .container ul,
  .containerBlack ul,
  .containerGreen ul,
  .containerGrey ul {
    gap: 40px;
  }
}
