.container ul,
.containerWhite ul {
  display: flex;
  gap: 18px;
  list-style: none;
}

.containerWhite ul li a,
.container ul li a {
  text-decoration: none;
  color: #000000;
  font-size: 1.1rem;
  font-weight: 500;
  transition: 0.25s ease-in-out;
}

.containerWhite ul li a {
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
}

.containerWhite ul li a:hover {
  color: rgb(200, 200, 200);
}
.container ul li a:hover {
  color: rgb(37, 49, 105);
}
.containerWhite ul li.active a,
.container ul li.active a {
  border-bottom: 1px solid #000;
}
