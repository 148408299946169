.container {
  /* height: 100%; */
  min-height: 300px;
  height: 100%;
  width: 100%;
  padding: 30px;
  background-color: rgb(236, 252, 252);
  border: 1px solid #000;
}

.title {
  padding-bottom: 30px;
  text-transform: uppercase;
}

.content {
  display: grid;
  gap: 15px;
}

.icon {
  font-size: 15px;
  padding: 10px;
  border-radius: 50%;
  background-color: rgb(193, 253, 253);
  border: 0.5px solid #ccc;
  box-shadow: 2px 2px 2px rgba(204, 204, 204, 0.5);
}

.fileSaved {
  position: relative;
  height: 120px;
  border-radius: 15px;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.iconTrash {
  font-size: 0.8rem;
  color: white;
}

.fileIcons {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 7px;
  background-color: rgb(52, 52, 52);
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  gap: 10px;
}

.listStyle {
  border-top: 1px solid rgb(117, 117, 117);
  padding: 20px 0px;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-template-rows: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: auto; */
  gap: 30px;
}

.fileName {
  font-size: 0.75rem;
  padding-right: 10px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iconTrash:hover {
  color: rgb(255, 204, 171);
}

.iconTrash:active {
  color: rgb(252, 101, 0);
}

.fieldInput {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sendFilesButton:hover {
  background-color: rgb(215, 168, 254);
}

.sendFilesButton:active {
  background-color: rgb(247, 236, 255);
}

.sendFilesButton:active .icon {
  color: rgb(0, 0, 0);
}

.icon:hover {
  background-color: rgb(71, 255, 255);
}

.icon:active {
  background-color: rgb(0, 232, 232);
  box-shadow: 2px 2px 5px rgba(204, 204, 204, 0.5);
}
