.container {
  height: 80px;
  width: 100%;
}

.select {
  height: 35px;
  width: 100%;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease-in-out;
}

.select:disabled {
  background-color: #efefef;
}

.select.error {
  border: 1px solid #ff452b;
}
.select:focus {
  outline: none;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.label {
  font-size: 0.8rem;
  letter-spacing: 1px;
  line-height: 1.2rem;
  font-weight: 600;
}

.messageError {
  font-size: 0.9rem;
  color: var(--color-error-message);
  padding: 5px 0px 5px 0px;
}

/* Tablet */
@media screen and (min-width: 765px) {
  .select {
    height: 40px;
  }
}
