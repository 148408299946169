.button {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  color: #000;
  font-weight: 600;
  letter-spacing: 1.5px;
  /* background: rgb(255, 225, 225); */
  /* border: 2px solid rgb(255, 21, 21); */
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}

.button:hover {
  color: #fff;
  /* background: rgb(255, 21, 21); */
}

/* --- SEND --- */
.send {
  background: rgb(34, 95, 151);
  color: #fff;
}

.send:hover {
  background: rgb(140, 197, 250);
  color: rgb(0, 0, 0);
}

.send:active {
  background: rgb(95, 173, 245);
  color: rgb(0, 0, 0);
  box-shadow: none;
}

/* --- LOGIN --- */
.send {
  background: rgb(26, 90, 150);
  color: #fff;
}

.send:hover {
  background: rgb(140, 197, 250);
  color: rgb(0, 0, 0);
}

.send:active {
  background: rgb(95, 173, 245);
  color: rgb(0, 0, 0);
  box-shadow: none;
}
