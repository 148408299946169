.whiteRight,
.whiteLeft {
  color: #ffffff;
  /* background: linear-gradient(120deg, #d2af6d, #d2af6d); */
}

.blackRight,
.blackLeft {
  color: #002e2c;
  /* background: linear-gradient(120deg, #d2af6d, #d2af6d); */
}

.yellowRight,
.yellowLeft {
  color: #d2af6c;
  /* background: linear-gradient(120deg, #ffffff, #ffffff); */
}

.whiteLeft,
.blackLeft,
.yellowLeft {
  background-repeat: no-repeat;
  background-size: 100% 8px;
  background-position: 0px 17px;
}

.whiteRight,
.blackRight,
.yellowRight {
  background-repeat: no-repeat;
  background-size: 100% 8px;
  background-position: 40px 17px;
}

.title {
  transform: scale(1, 1.05);
  font-size: 1.1rem;
  font-weight: 400;
  /* letter-spacing: 1.6px; */
  letter-spacing: 4px;
}

.strong {
  font-weight: 700;
  font-style: normal;
}

@media screen and (min-width: 765px) and (max-width: 1200px) {
  .title {
    font-size: 1.3rem;
  }

  .whiteLeft,
  .blackLeft,
  .yellowLeft {
    background-size: 100% 8px;
    background-position: 0px 18px;
  }

  .whiteRight,
  .blackRight,
  .yellowRight {
    background-size: 100% 8px;
    background-position: 50px 18px;
  }
}

@media screen and (min-width: 1201px) {
  .title {
    font-size: 1.5rem;
  }

  .whiteLeft,
  .blackLeft,
  .yellowLeft {
    background-size: 100% 10px;
    background-position: 0px 25px;
  }

  .whiteRight,
  .blackRight,
  .yellowRight {
    background-size: 100% 10px;
    background-position: 50px 25px;
  }
}
